import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosApi from "../../helper/axiosApi";
import state from "./state";

// Get all sftp document list
export const sftpFileList = createAsyncThunk(
  "sftpFileList",
  async ({ id, pageSize, page }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `stmt-transform/api/v1/companies/list?company_id=${id}&page_size=${pageSize}&page=${page}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCompanySftpStatus = createAsyncThunk(
  "getCompanySftpStatus",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(`company/api/v1/company`);
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//download report
export const downloadComapnyBulkFile = createAsyncThunk(
  "downloadComapnyBulkFile",
  async (
    { companyId, id, fileName, type, statementType },
    { rejectWithValue }
  ) => {
    try {
      axiosApi
        .get(
          `stmt-transform/api/v1/companies/${companyId}/${statementType}-statements/${id}/${type}`,

          {
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/octet-stream",
              responseType: "arraybuffer",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();

          return response.data;
        });
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get searched sftp document list
export const sftpSearchedFileList = createAsyncThunk(
  "sftpSearchedFileList",
  async (
    { id, pageSize, page, start_date, end_date, doc_type },
    { rejectWithValue }
  ) => {
    try {
      const res = await axiosApi.get(
        `stmt-transform/api/v1/companies/search?company_id=${id}&page_size=${pageSize}&page=${page}&start_date=${start_date}&end_date=${end_date}&doc_type=${doc_type}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const masterDataSlice = createSlice({
  name: "sftpDataSlice",
  initialState: state,
  extraReducers: {
    [sftpFileList.pending]: (state, action) => {
      state.status = "loading";
      state.type = "SFTP_DATA_LIST";
    },
    [sftpFileList.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "SFTP_DATA_LIST";
      state.sftpList = action.payload;
    },
    [sftpFileList.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "SFTP_DATA_LIST";
      state.error = action.payload;
    },
    [getCompanySftpStatus.pending]: (state, action) => {
      state.status = "loading";
      state.type = "SFTP_DATA_LIST";
    },
    [getCompanySftpStatus.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "SFTP_DATA_LIST";
      state.sftp = action.payload?.data[0]?.sftp;
    },
    [getCompanySftpStatus.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "SFTP_DATA_LIST";
      state.error = action.payload;
    },
    [downloadComapnyBulkFile.pending]: (state, action) => {
      state.status = "loading";
      state.type = "DOWNLOAD_COMPANY_BULK_FILE";
    },
    [downloadComapnyBulkFile.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "DOWNLOAD_COMPANY_BULK_FILE";
    },
    [downloadComapnyBulkFile.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "DOWNLOAD_COMPANY_BULK_FILE";
      state.error = action.payload;
    },
    [sftpSearchedFileList.pending]: (state, action) => {
      state.status = "loading";
      state.type = "SFTP_SEARCHED_FILE_LIST";
    },
    [sftpSearchedFileList.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "SFTP_SEARCHED_FILE_LIST";
      state.sftpSearchedList = action.payload;
    },
    [sftpSearchedFileList.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "SFTP_SEARCHED_FILE_LIST";
      state.sftpSearchedList = [];
      state.error = action.payload;
    },
  },
});

const { reducer } = masterDataSlice;

export default reducer;
