/**
 * @purpose : Export routes path
 */
export const DIRECT_RECO_STATEMENT = "direct-reco-statements";
export const DIRECT_RECO_TABLE = "directrecotable";
export const RECO_SUMMARY = "recosummary";
export const EDIT_SETTINGS = "editsettings";
export const SELECT_PARTY = "selectparty";
export const ERROR = "error";
export const IMPORT_DATATABLE = "importdatatable";
export const PARTY_MASTER_DATA = "partymasterdata";
export const PARTY_MASTER_HOME = "partymasterhome";
export const UPLOAD_FILE = "uploadfile";
export const ADD_NEW_PARTY = "addnewparty";
export const INTERANL_TEAM = "internalteam";
export const OTHER_DETAILS = "otherdetails";
export const CONST_DATATABLE = "constdatatable";
export const COMPANY_STATEMENT = "companystatement";
export const MODAL = "modal";
export const VERIFY = "verify";
export const VERIFY_DONE = "verifydone";
export const PROGRESS = "progress";
export const REQUEST_STATEMENT = "requeststatement";
export const REQUEST_STATEMENT_MAIN = "requeststatementmain";
export const VIEW_UPLOAD_SCREEN = "viewuploadscreen";
export const MARK_AS_RESPOND = "markrespond";
export const MATCHED_DATA = "matcheddata";
export const UNMATCHED_DATA = "unmatcheddata";
export const AUTOMATION = "automation";
export const SCHEDULER = "scheduler";
export const NEW = "new";
export const SETTINGS = "settings";
export const RULE_GROUP = "rulegroup";
export const ADD_RULE = "addrule";
export const ASSING_RULES_TO_COMPANY = "assignedrulestocompany";
export const VIEW_ACTION_TRACKER_TABLE = "viewactiontrackertable";
export const ATTACHMENTS_AND_COMMENTS = "attachmentsandcomments";
export const ACTION_RECO_SUMMARY = "actionrecosummary";
export const RECO_SETTINGS = "reco-setting";
export const COMPANY_RECO_SETTINGS = "companyrecosettings";
export const CONFIRMATION = "confirmation";
export const PARTY_RECO_SUMMARY = "partyrecosummary";
export const DOWNLOAD = "download";
export const PDF = "pdf";
export const LOGGED_OUT = "loggedout";
export const TOKEN_EXPIRE = "tokenexpire";
export const CONVERSATION_LOGS = "conversation-logs";
export const SFTP = "sftp";

export const FIRMWAY_WEBSITE_URL = "https://firmway.in";
export const FIRMWAY_TAWK_HELP_URL = "https://firmway.tawk.help/";
export const YOUTUBE_PLAYLIST_URL =
  "https://www.youtube.com/playlist?list=PLqRGRKybfZG78nfe4DlSHlwZcf90jJF3N";
export const HOW_TO_RESPOND_URL =
  "https://www.youtube.com/watch?v=7qPrfi8m84k&list=PLqRGRKybfZG7VDTn8ITQsntqH--iwetpw";
export const STATEMENT_STATUS_LINK =
  "https://support.firmway.in/portal/en/kb/articles/statement-reco-status";

export const AMOUNT_STATISTICS_LENGTH = 5;

export const CLEAR_RECO_STATUSES = [
  "Reco_Rejected",
  "Auto_Completed",
  "Review_Pending",
  "Reviewed",
];

export const RESPONDED_COLOR = "rgba(237, 125, 49, 1)";
export const STATEMENT_RECEIVED_COLOR = "rgba(255, 87, 208, 1)";
export const MAPPING_PENDING_COLOR = "rgba(255, 79, 79, 1)";
export const STATEMENT_MAPPED_COLOR = "rgba(241, 232, 0, 1)";
export const IN_PROGRESS_COLOR = "rgba(68, 114, 196, 1)";
export const AUTO_COMPLETED_COLOR = "rgba(255, 113, 113, 1)";
export const REVIEW_PENDING_COLOR = "rgba(165, 165, 165, 1)";
export const REVIEWED_COLOR = "rgba(255, 168, 35, 1)";
export const RECO_REJECTED_COLOR = "rgba(155, 70, 206, 1)";
export const SIGNOFF_REQUESTED_COLOR = "rgba(106, 200, 229, 1)";
export const QUERY_RAISED_COLOR = "rgba(81, 255, 153, 1)";
export const SIGNOFF_COMPLETED_COLOR = "rgba(13, 141, 214, 1)";
export const LIGHT_GREY_COLOR = "rgba(211, 211, 211, 0.6)";