import {
  Box,
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Table,
  TableBody,
  IconButton,
  TextField,
  Toolbar,
  MenuItem,
  Pagination,
  PaginationItem,
  Stack,
} from "@mui/material";
import { SftpDownloadIcon, SortLabel } from "components/AllSvg";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { visuallyHidden } from "@mui/utils";
import styled from "styled-components/macro";
import { setSort } from "store/newparty/slice";
import dayjs from "dayjs";
import SftpMenu from "./SftpMenu";
import makeStyles from "@mui/styles/makeStyles";
import { downloadComapnyBulkFile, sftpFileList } from "store/sftp/slice";
import IsLoadingHOC from "components/loader/loader";

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f6f6f6",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const headCells = [
  {
    id: "download",
    disablePadding: false,
    label: "",
    sort: false,
  },
  {
    id: "fileName",
    disablePadding: false,
    label: "File Name",
    sort: true,
  },
  {
    id: "statementPeriod",
    disablePadding: false,
    label: "Statement Period",
    sort: true,
  },
  {
    id: "dateTime",
    disablePadding: false,
    label: "Date & Time",
    sort: true,
  },
  {
    id: "type",
    disablePadding: false,
    label: "Type",
    sort: true,
  },
  {
    id: "size",
    disablePadding: false,
    label: "Size",
    sort: true,
  },
  {
    id: "status",
    disablePadding: false,
    label: "Status",
    sort: true,
  },
  {
    id: "newFile",
    disablePadding: false,
    label: "New File",
    sort: true,
  },
  {
    id: "action",
    disablePadding: false,
    label: "Action",
    sort: true,
  },
];

function EnhancedTableHead() {
  const dispatch = useDispatch();
  const { sort } = useSelector((state) => state.trackreconciliation);

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={index === 2 ? "left" : "center"}
            sortDirection={
              sort.id === headCell.id
                ? sort.direction === "ascending"
                  ? "asc"
                  : "desc"
                : "asc"
            }
            style={{ backgroundColor: "#FFF" }}
          >
            {headCell?.sort && (
              <TableSortLabel
                IconComponent={() => (
                  <Box sx={{ marginTop: "15px" }}>
                    <SortLabel />
                  </Box>
                )}
                active={headCell.id === sort.id}
                direction={
                  sort.id === headCell.id
                    ? sort.direction === "ascending"
                      ? "asc"
                      : "desc"
                    : "asc"
                }
                onClick={() =>
                  dispatch(
                    setSort({
                      id: headCell.id,
                      direction:
                        sort.direction === "ascending"
                          ? "descending"
                          : "ascending",
                    })
                  )
                }
              >
                {headCell.label}
                {sort.id === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {sort.direction === "ascending"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const SftpTable = ({ setLoading }) => {
  const { sftpList } = useSelector((state) => state.sftp);
  const { companyId } = useSelector((state) => state.masterData);

  const [rowData, setRowData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(
      sftpFileList({ id: companyId, pageSize: rowsPerPage, page: page })
    ).then(() => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, rowsPerPage]);

  useEffect(() => {
    let obj = sftpList?.statements?.map((val) => {
      return {
        filename: val?.filename,
        start_date: val?.start_date,
        end_date: val?.end_date,
        created_date: val?.created_date,
        doc_type: val?.doc_type,
        file_size: val?.file_size,
        status: val?.status,
        bulk_file_key: val?.bulk_file_key,
        company_file_key: val?.company_file_key,
        original_file_key: val?.original_file_key,
        company_id: val?.company_id,
        company_code: val?.company_code,
        id: val?.id,
        bulk_file_id: val?.bulk_file_id,
      };
    });
    setRowData(obj);
  }, [sftpList]);

  /**
   * @purpose : Format date
   * @param {*} dateString
   * @returns
   */
  const dateFormate = (date) => {
    if (date === null || date === "" || date === undefined) {
      return "-";
    }
    return dayjs(date).format("DD-MMM-YY");
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(1);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const useStyles = makeStyles(() => ({
    selected: {
      color: "#FFFFFF",
      background: "#005724",
    },
    root: {
      color: "#005724",
      background: "#FFFFFF",
      border: "1px solid #005724",
      borderRadius: "4px",
    },
  }));

  const classes = useStyles();

  const handleDownloadOriginalFile = (companyId, id, fileName) => {
    dispatch(
      downloadComapnyBulkFile({
        companyId,
        id,
        fileName,
        statementType: "company",
        type: "original",
      })
    );
  };

  const handleDownloadTrasformedFile = (
    companyId,
    id,
    fileName,
    statementType
  ) => {
    dispatch(
      downloadComapnyBulkFile({
        companyId,
        id,
        fileName,
        type: "transformed",
        statementType,
      })
    );
  };
  return (
    <>
      <Box bgcolor={"#F3F3F3"} padding="16px" borderRadius={"7.5px"}>
        <Paper
          style={{
            border: "0.5px solid #BBBBBB",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <TableContainer sx={{ maxHeight: "70vh" }}>
            <Table
              stickyHeader
              aria-labelledby="tableTitle"
              size={"small"}
              sx={{ bgcolor: "#FFF", borderRadius: "7.5px" }}
            >
              <EnhancedTableHead />
              <TableBody>
                {rowData?.map((row, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <TableCell align="right" style={{ padding: "0px" }}>
                        <IconButton
                          onClick={() => {
                            handleDownloadOriginalFile(
                              row.company_id,
                              row.id,
                              row.filename
                            );
                          }}
                        >
                          <SftpDownloadIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell align="left" style={{ paddingInline: "0px" }}>
                        {row.filename}
                      </TableCell>
                      <TableCell align="center">
                        {dateFormate(row.start_date) +
                          "-to-" +
                          dateFormate(row.end_date)}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(row.created_date).format(
                          "DD-MMM-YYYY hh:mm:ss A"
                        )}
                      </TableCell>
                      <TableCell align="center">{row.doc_type}</TableCell>
                      <TableCell align="center">{row.file_size}</TableCell>
                      <TableCell
                        align="left"
                        style={{
                          color:
                            row.status === "Error in File" ? "red" : "#000",
                        }}
                      >
                        {row.status}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          color:
                            row.status === "Error in File"
                              ? "red"
                              : row.status === "Ready to Use"
                              ? "#005724"
                              : "#000",
                        }}
                      >
                        <Typography
                          fontFamily={"ekmukta-bold"}
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            const statementType =
                              row.id === row.bulk_file_id ? "bulk" : "company";
                            if (row.status === "Ready to Use") {
                              handleDownloadTrasformedFile(
                                row.company_id,
                                row.id,
                                row.filename,
                                statementType
                              );
                            }
                          }}
                        >
                          {row.status === "Error in File" ||
                          row.status === "Ready to Use"
                            ? "Download"
                            : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {row.id === row.bulk_file_id && <SftpMenu />}
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pr: { xs: 1, sm: 1 },
          }}
          style={{ marginLeft: "-20px" }}
        >
          <TextField
            select
            size="small"
            className="page-per-row"
            defaultValue={10}
            onChange={handleChangeRowsPerPage}
            sx={{
              ".MuiSelect-select": {
                fontFamily: "SFProdisplay",
                fontStyle: "normal",
                fontWeight: "700",
                color: "#005724",
              },
              ".MuiSvgIcon-root-ffqUiY": {
                color: "#005724",
              },
            }}
          >
            <MenuItem className="menu-items" value={10}>
              10 per page
            </MenuItem>
            <MenuItem value={25}>25 per page</MenuItem>
            <MenuItem value={50}>50 per page</MenuItem>
            <MenuItem value={100}>100 per page</MenuItem>
          </TextField>

          <Stack spacing={2} className="pagination-box">
            <Pagination
              page={page}
              shape="rounded"
              component="div"
              defaultPage={1}
              count={sftpList?.total_pages ?? 1}
              onChange={handleChangePage}
              renderItem={(item) => (
                <PaginationItem
                  variant="outlined"
                  {...item}
                  classes={{
                    root: classes.root,
                    selected: classes.selected,
                  }}
                />
              )}
            />
          </Stack>
        </Toolbar>
      </Box>
    </>
  );
};

export default IsLoadingHOC(SftpTable);
